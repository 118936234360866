import "./Help.scss";
import help from "../../Assets/Images/help.png";
import { Container } from "@material-ui/core";
import fontawesome from "@fortawesome/fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

const Help = (props) => {
  fontawesome.library.add(faPlayCircle);
  var url = {
    zoom: "https://blog.zoom.us/zoom-acquires-keybase-and-announces-goal-of-developing-the-most-broadly-used-enterprise-end-to-end-encryption-offering/",
    techcrunch1:
      "https://techcrunch.com/2021/05/26/skiff-encrypted-collaboration-seed/",
    techcrunch2: "https://techcrunch.com/2021/05/19/__trashed-13/",
    tigerconnect:
      "https://tigerconnect.com/products/care-team-collaboration/secure-text-messaging/",
    apple:
      "https://support.apple.com/en-gb/guide/security/sec0a319b35f/1/web/1",
    signal: "https://signal.org/docs/",
    WhatsApp: "https://www.whatsapp.com/security/",
    dashlane:
      "https://www.dashlane.com/download/Dashlane_SecurityWhitePaper_March2021.pdf",
    onePassword: "https://support.1password.com/1password-security/",
    makeUse: "https://www.makeuseof.com/apps-use-end-to-end-encryption/",
  };
  return (
    <div className="help-container">
      <Container>
        <div className="help">
          <div className="upper-section">
            {/* <div className="video">
              <div className="thumbnail"></div>
              <div className="play-button-background"></div>
              <div className="play-button">
                <FontAwesomeIcon icon="play-circle" />
              </div>
              <div className="bottom">
                <img src={videoBottom} alt="Video" />
              </div>
              <div className="side">
                <div className="hr"></div>
                <div className="square">
                  <div className="circle"></div>
                </div>
              </div>
            </div> */}
            <div className="heading">
              <img src={help} alt="Help" />
              <p>See how BAYUN can help to Secure you and your Customers</p>
            </div>
          </div>
          <div className="content">
            <p>
              Quoting Prof. Madnick again, the best way to protect user data for
              any application or service is to retain as little of it as is
              necessary and, more importantly, what-ever data is stored should
              be protected using the strongest version of end-to-end encryption
              techniques where it can only be decrypted by the data owners. It
              is for this very reason, that some of the leading{" "}
              <a href={url.makeUse}>SaaS vendors</a> have spent years building
              and honing the end-to-end-encryption technologies, which enables
              them to differentiate and compete in today’s fast-emerging
              privacy-focused environment, and maintain the ultimately best
              possible security for themselves and their customers. Most of them
              have built this technology in-house, from scratch, for their own
              specific use-cases, e.g. Zoom (through
              <a href={url.zoom}> Keybase</a> acquisition),{" "}
              <a href={url.techcrunch1}>Skiff</a> (now part of Notion),{" "}
              <a href={url.techcrunch2}>ProtonMail</a> ,{" "}
              <a href={url.tigerconnect}>TigerText</a> ,{" "}
              <a href={url.signal}>Signal</a> and{" "}
              <a href={url.WhatsApp}>WhatsApp</a> ,{" "}
              <a href={url.dashlane}>Dashlane</a> and{" "}
              <a href={url.onePassword}>1Password</a> , and most-importantly{" "}
              <a href={url.apple}>iCloud Keychain</a>
            </p>
            <p>
              Bayun’s DigiLockbox technology enables any software vendor to
              quickly integrate E2EE into your products within days (instead of
              spending years building it in-house), including the ability to
              provide complete enterprise controls to your customers (e.g.
              ability to securely transfer control of E2E encrypted data on
              departure of a disgruntled employee) - something that is necessary
              in an enterprise environment, or any other use-case where stakes
              can be high (e.g. in crypto-wallets); but not provided by any of
              these existing products. Bayun SDK’s password-less auth makes it
              possible to start with improved user-experience for your customers
              first, and roll out data encryption in stages to get on par with,
              or rather get ahead of, most other solutions providing E2EE.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Help;
